import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Select } from 'antd'

import axiosRequest from '../../../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../../../helpers/axios-error.helper'

const ChangePropertyManager = ({
  property,
  visible,
  setVisibility,
  getData,
}) => {
  const [loading, setLoading] = useState(false)
  const [usersLoading, setUsersLoading] = useState(true)
  const [managers, setManagers] = useState([])

  useEffect(() => {
    if (visible && property.id) {
      setUsersLoading(true)
      axiosRequest(
        'api/v1/users?page=1&perPage=9999&type=acquisition',
        'get',
        null,
        true,
      )
        .then((res) => {
          setManagers(res.data.users.users)
        })
        .catch((e) => axiosErrorHelper(e))
        .finally(() => setUsersLoading(false))
    }
  }, [visible, property])

  const onFinish = (values) => {
    setLoading(true)
    axiosRequest(
      `api/v1/acquisition/data/properties/${property.id}`,
      'patch',
      { AgentId: values.AgentId || property.AgentId },
      true,
    )
      .then(() => {
        getData()
        setVisibility()
      })
      .catch((e) => {
        axiosErrorHelper(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (!property) {
    return null
  }

  return (
    <Modal
      title={`${property.ACRES} acre in ${property.SitusCity}, ${property.SitusState}`}
      visible={visible}
      onCancel={() => setVisibility()}
      destroyOnClose
      footer={false}
      centered
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
        initialValues={{ AgentId: property.AgentId }}
      >
        <Form.Item
          name="AgentId"
          label="Manager"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Select loading={usersLoading}>
            {managers.map((manager) => (
              <Select.Option key={manager.id} value={manager.id}>
                {manager.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className="mb-0">
          <Button
            className="btn btn-sm btn-primary"
            htmlType="submit"
            loading={loading}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ChangePropertyManager
