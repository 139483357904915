import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Tabs, Pagination, Button } from 'antd'
import { RiFilter3Fill } from 'react-icons/ri'

import PageTitle from '../../../components/ui/title/PageTitle'

import axiosRequest from '../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../helpers/axios-error.helper'
import convertJsonToUrlParams from '../../../helpers/convert-json-to-url-params'
import AcquisitionProperties from '../../../components/pages/acquisition/data-parser/properties'
import FiltersDrawer from '../../../components/pages/acquisition/filters-drawer'

const LeadsPoolPage = () => {
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('myLeads')
  const [properties, setProperties] = useState({ properties: [], total: 0 })
  const [filters, setFilters] = useState({ page: 1, perPage: 24 })
  const [filtersDrawer, setFiltersDrawer] = useState(false)

  const getData = (withLoading = true, cb = () => {}) => {
    if (withLoading) {
      setLoading(true)
    }
    axiosRequest(
      activeTab === 'waitingForResponseAll'
        ? `api/v1/acquisition/data/properties${convertJsonToUrlParams(
            filters,
          )}&Status=req_sent&NoAgents=1`
        : `api/v1/acquisition/data/properties${convertJsonToUrlParams(
            filters,
          )}${activeTab === 'myLeads' ? '&Status=active' : ''}${
            activeTab !== 'myLeads' ? `&Status=${activeTab}` : ''
          }${activeTab !== 'active' ? '&MyLeads=1' : '&LeadsPool=1'}`,
      'get',
      null,
      true,
    )
      .then((res) => {
        setProperties({
          properties: res.data.data.properties,
          total: res.data.data.total,
        })
        cb()
      })
      .catch((e) => axiosErrorHelper(e))
      .finally(() => {
        if (withLoading) {
          setLoading(false)
        }
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [activeTab, filters])

  return (
    <>
      <Helmet title="Leads Pool" />
      <PageTitle
        title="Leads Pool"
        extraButtons={
          <>
            <Button
              icon={<RiFilter3Fill />}
              className="btn-sm btn-primary fw-500"
              onClick={() => setFiltersDrawer(true)}
            >
              Data Filters
            </Button>
          </>
        }
      />
      <div>
        <Tabs
          activeKey={activeTab}
          onChange={(activeKey) => {
            setFilters({ page: 1, perPage: 24 })
            setActiveTab(activeKey)
          }}
        >
          <Tabs.TabPane tab="Leads Pool" key="active">
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              loading={loading}
              showControlButtons={false}
              showContacts={false}
              showSubmitButton
              allowChangeStatuses={false}
              setActiveTab={setActiveTab}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Waiting For Response Pool"
            key="waitingForResponseAll"
          >
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              loading={loading}
              showControlButtons={false}
              showContacts={false}
              showSubmitButton
              allowChangeStatuses={false}
              setActiveTab={setActiveTab}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="My Leads" key="myLeads">
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              loading={loading}
              showControlButtons={false}
              setActiveTab={setActiveTab}
              showContacts
              agentView
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="In Negotiations" key="LP_InNegotiations">
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              loading={loading}
              showControlButtons={false}
              setActiveTab={setActiveTab}
              showContacts
              agentView
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Waiting for Response" key="req_sent">
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              loading={loading}
              showControlButtons={false}
              setActiveTab={setActiveTab}
              showContacts
              agentView
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Waiting for Approval" key="LP_WaitingForApproval">
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              loading={loading}
              showControlButtons={false}
              setActiveTab={setActiveTab}
              showContacts
              agentView
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Call Back" key="LP_CallBack">
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              loading={loading}
              showControlButtons={false}
              setActiveTab={setActiveTab}
              showContacts
              agentView
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Closed Lost" key="lost">
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              loading={loading}
              showControlButtons={false}
              setActiveTab={setActiveTab}
              showContacts={false}
              agentView
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Closed Won" key="success">
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              loading={loading}
              showControlButtons={false}
              setActiveTab={setActiveTab}
              showContacts={false}
              agentView
            />
          </Tabs.TabPane>
        </Tabs>
        {filters.perPage < properties.total && (
          <Pagination
            className="mt-4"
            size="small"
            current={filters.page}
            pageSize={filters.perPage}
            total={properties.total}
            showSizeChanger={false}
            onChange={(page) => {
              if (window) {
                window.scrollTo(0, 0)
              }
              setFilters({ ...filters, page })
            }}
          />
        )}
      </div>
      <FiltersDrawer
        visible={filtersDrawer}
        setVisibility={setFiltersDrawer}
        filters={filters}
        setFilters={setFilters}
        activeTab={activeTab}
        isLeadsPool={activeTab === 'active'}
        hideManager
      />
    </>
  )
}

export default LeadsPoolPage
