import React from 'react'

import SidebarMenu from './components/menu/SidebarMenu'

import styles from './../main.module.scss'
import sidebarMenu from '../../../data/main-menu'

const Sidebar = ({ toggle, setToggle, user }) => {
  return (
    <aside
      className={`${styles.sidebar} ${!toggle ? styles.sidebarSmall : ''}`}
    >
      <div className={styles.head}>
        <div className={styles.logo}>PortalStation</div>
        <div className={`${styles.toggle} ${!toggle ? '' : styles.toggleOpen}`}>
          <button
            onClick={(e) => {
              e.preventDefault()
              console.log(toggle)
              console.log(!toggle)
              setToggle(!toggle)
            }}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              className="toggle__icon toggle__icon_active"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path fill="none" d="M0 0H24V24H0z" />
                <path d="M21 18v2H3v-2h18zM17.05 3.55L22 8.5l-4.95 4.95v-9.9zM12 11v2H3v-2h9zm0-7v2H3V4h9z" />
              </g>
            </svg>
          </button>
        </div>
      </div>
      <SidebarMenu sidebarMenu={sidebarMenu} user={user} />
    </aside>
  )
}

export default Sidebar
