import LeadsPage from '../pages/crm/Leads'
import ClientsPage from '../pages/crm/Clients'
import NewsletterSubscribersPage from '../pages/crm/NewsletterSubscribers'

const crmRoutes = [
  {
    path: '/crm/leads/:slug',
    Component: LeadsPage,
  },
  {
    path: '/crm/clients',
    Component: ClientsPage,
  },
  {
    path: '/crm/newsletter-subscribers',
    Component: NewsletterSubscribersPage,
  },
]

export default crmRoutes
