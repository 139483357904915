import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Sidebar from './partials/Sidebar'
import Header from './partials/Header'
import Footer from './partials/Footer'

import styles from './main.module.scss'

const mapStateToProps = ({ users }) => ({ users })

const MainLayout = ({ children, users }) => {
  const [sidebarToggle, setSidebarToggle] = useState(true)

  return (
    <div className={styles.mainLayout}>
      <Sidebar
        toggle={sidebarToggle}
        setToggle={setSidebarToggle}
        user={users.user}
      />
      <div
        className={`${styles.content} ${
          sidebarToggle ? styles.contentDefault : styles.contentSmall
        }`}
      >
        <Header user={users.user} />
        <main>
          <div className={styles.container}>{children}</div>
        </main>
        <Footer />
      </div>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))
