import React, { useEffect, useState } from 'react'
import { Button, Tabs, Pagination, notification } from 'antd'
import { RiAddFill, RiFilter3Fill, RiUpload2Fill } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'

import PageTitle from '../../../components/ui/title/PageTitle'
import checkPermission from '../../../helpers/check-permission'
import AcquisitionProperties from '../../../components/pages/acquisition/data-parser/properties'
import ParserModal from '../../../components/pages/acquisition/data-parser/parser-modal/ParserModal'
import axiosRequest from '../../../helpers/axios-request.helper'
import axiosErrorHelper from '../../../helpers/axios-error.helper'
import convertJsonToUrlParams from '../../../helpers/convert-json-to-url-params'
import UploadCSVPropertiesModal from '../../../components/pages/acquisition/data-parser/properties/UploadCSVPropertiesModal'
import FiltersDrawer from '../../../components/pages/acquisition/filters-drawer'
import ActivityLogs from '../../../components/pages/acquisition/data-parser/activity-logs'

const ParserPage = () => {
  const users = useSelector((state) => state.users)
  const { permissions } = users
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('all')
  const [properties, setProperties] = useState({ properties: [], total: 0 })
  const [filters, setFilters] = useState({ page: 1, perPage: 24 })
  const [parserModal, setParserModal] = useState(false)
  const [uploaderModal, setUploaderModal] = useState(false)
  const [filtersDrawer, setFiltersDrawer] = useState(false)

  const getData = (withLoading = true) => {
    if (checkPermission(permissions, 'acquisitionParser')) {
      if (withLoading) {
        setLoading(true)
      }
      axiosRequest(
        `api/v1/acquisition/data/properties${convertJsonToUrlParams(filters)}${
          activeTab !== 'all' ? `&Status=${activeTab}` : ''
        }`,
        'get',
        null,
        true,
      )
        .then((res) => {
          setProperties({
            properties: res.data.data.properties,
            total: res.data.data.total,
          })
        })
        .catch((e) => axiosErrorHelper(e))
        .finally(() => {
          if (withLoading) {
            setLoading(false)
          }
        })
    } else {
      notification.error({
        message: 'Error',
        description: 'Access Denied',
      })
    }
  }

  useEffect(() => {
    const querySearch = new URLSearchParams(window.location.search)
    if (querySearch.get('uploaderFile')) {
      setFilters({
        ...filters,
        uploaderFile: parseInt(querySearch.get('uploaderFile'), 10),
      })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (activeTab !== 'activityLogs') {
      getData()
    }
    // eslint-disable-next-line
  }, [activeTab, filters])

  return (
    <>
      <Helmet title="Data Parser" />
      <PageTitle
        title="Data Parser"
        breadcrumbs={[
          { path: '/', name: 'Dashboard' },
          {
            path: '/acquisition/data-parser',
            name: `Acquisition Properties (${properties.total})`,
          },
        ]}
        extraButtons={
          <>
            {checkPermission(permissions, 'acquisitionParser') && (
              <>
                <Button
                  icon={<RiUpload2Fill />}
                  className="btn-sm btn-primary fw-500 me-3"
                  onClick={() => {
                    setUploaderModal(true)
                  }}
                >
                  Upload CSV
                </Button>
                <Button
                  disabled
                  icon={<RiAddFill />}
                  className="btn-sm btn-primary fw-500 me-3 d-none"
                  onClick={() => {
                    setParserModal(true)
                  }}
                >
                  Open Parser
                </Button>
              </>
            )}
            {checkPermission(permissions, 'acquisitionParser') && (
              <Button
                icon={<RiFilter3Fill />}
                className="btn-sm btn-primary fw-500"
                onClick={() => setFiltersDrawer(true)}
              >
                Data Filters
              </Button>
            )}
          </>
        }
      />
      <div>
        <Tabs
          activeKey={activeTab}
          onChange={(activeKey) => {
            setFilters({ page: 1, perPage: 24 })
            setActiveTab(activeKey)
          }}
        >
          <Tabs.TabPane tab="All Leads" key="all">
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              setActiveTab={setActiveTab}
              loading={loading}
              allowAssignManagers
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Unproccessed" key="unprocessed">
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              setActiveTab={setActiveTab}
              loading={loading}
              allowAssignManagers
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Waiting For Response" key="req_sent">
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              setActiveTab={setActiveTab}
              loading={loading}
              allowAssignManagers
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Not Answered (Admin)" key="not_answered">
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              setActiveTab={setActiveTab}
              loading={loading}
              allowAssignManagers
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Active" key="active">
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              setActiveTab={setActiveTab}
              loading={loading}
              allowAssignManagers
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="In Negotiations (LP)" key="LP_InNegotiations">
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              setActiveTab={setActiveTab}
              loading={loading}
              allowAssignManagers
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Waiting for Approval (LP)"
            key="LP_WaitingForApproval"
          >
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              setActiveTab={setActiveTab}
              loading={loading}
              allowAssignManagers
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Call Back (LP)" key="LP_CallBack">
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              setActiveTab={setActiveTab}
              loading={loading}
              allowAssignManagers
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Closed Lost" key="lost">
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              setActiveTab={setActiveTab}
              loading={loading}
              allowAssignManagers
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Closed Won" key="success">
            <AcquisitionProperties
              getData={getData}
              properties={properties.properties}
              setActiveTab={setActiveTab}
              loading={loading}
              allowAssignManagers
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Activity Logs" key="activityLogs">
            <ActivityLogs activeTab={activeTab} />
          </Tabs.TabPane>
        </Tabs>
        {activeTab !== 'activityLogs' && filters.perPage < properties.total ? (
          <Pagination
            className="mt-4"
            size="small"
            current={filters.page}
            pageSize={filters.perPage}
            total={properties.total}
            showSizeChanger={false}
            onChange={(page) => {
              if (window) {
                window.scrollTo(0, 0)
              }
              setFilters({ ...filters, page })
            }}
          />
        ) : null}
      </div>
      <ParserModal
        visible={parserModal}
        setVisible={() => setParserModal(false)}
        getData={getData}
      />
      <UploadCSVPropertiesModal
        visible={uploaderModal}
        setVisibility={() => setUploaderModal(false)}
        getData={getData}
      />
      <FiltersDrawer
        visible={filtersDrawer}
        setVisibility={setFiltersDrawer}
        filters={filters}
        setFilters={setFilters}
        activeTab={activeTab}
      />
    </>
  )
}

export default ParserPage
